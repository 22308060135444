import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Card from '@mui/material/Card';

// custom components
import { Layout } from '../../components/layout/PageSingle';
import StyledBox from '../../components/controls/StyledBox';
import NewsletterConfirm from '../../custom/segments/NewsletterConfirm';
import Gallery from '../../custom/segments/Gallery';
import * as nodesUtils from '../../utils/nodes';

export const query = graphql`
  query ($id: String) {
    page: datoCmsPage(id: { eq: $id }) {
      pagetype
      title
      coverHeader
      teaser
      content
      footer
      coverImage {
        gatsbyImageData(width: 1600)
        alt
      }
      gallery {
        alt
        basename
        fixed(width: 250) {
          src
        }
        gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.3)
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    siteSetting: datoCmsSiteSetting {
      title
      company
      street
      street2
      zipCode
      city
      country
      phone
      fax
      mobile
      eMail
      website
      contactInfo
      contactAlias
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function NewsletterOkPage({ data, pageContext }) {
  const { page, siteSetting, site } = data;
  const { catalogType, catalog } = pageContext;
  const pageHeader = nodesUtils.getLabelFromPageType(page.pagetype);
  const coverHeader = page && page.coverHeader ? page.coverHeader : page.title;

  const galleryImages = nodesUtils.getGalleryImages(page.gallery);
  const pageParams = {
    title: coverHeader,
    cover: { ...page.coverImage },
  };

  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <HelmetDatoCms seo={page.seo} favicon={site.favicon} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -8,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StyledBox py={{ xs: 1, md: 2 }}>
          <NewsletterConfirm
            header={pageHeader}
            title={page.title}
            teaser={page.teaser}
            body={page.content}
            footer={page.footer}
            settings={siteSetting}
            showForm={false}
          />
        </StyledBox>
        <Gallery header={'Bildergalerie'} images={galleryImages} />
      </Card>
    </Layout>
  );
}
